<template>
	<div>
		<AppointmentForm
			is-update
			class="customer-update-appt"
			@submit-form="createNewAppt"
		></AppointmentForm>
	</div>
</template>

<script>
import { SEARCH_APPT, UPDATE_APPOINTMENT } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "CustomerUpdateAppt",

	components: {
		AppointmentForm: () => import("./../components/AppointmentForm.vue"),
	},

	props: {
		contactTool: {
			type: String,
			default: null,
		},
		contactInfo: {
			type: String,
			default: null,
		},
		code: {
			type: String,
			default: null,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {
		this.SEARCH_APPT({
			code: this.code,
			contactTool: this.contactTool,
			contactInfo: this.contactInfo,
		})

		// this.postToIframe()
	},

	methods: {
		...mapActions({ SEARCH_APPT, UPDATE_APPOINTMENT }),

		createNewAppt(params) {
			this.UPDATE_APPOINTMENT({
				planedDate: params.planedDate,
				timezone: params.timezone,
				note: params.note,
				clinicId: params.clinicId,
				serviceIds: params.serviceIds,
				id: params.id,
				code: params.code,
			})
		},

		postToIframe() {
			window.parent.postMessage("service-appt", "*")
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.customer-update-appt {
	background-color: $color-white;
	.appt-form {
		@include form;
		@include font-lato;

		&__heading {
			font-size: 16px;
			font-weight: 400;
			color: $color-black;

			h1 {
				font-size: 32px;
				color: $color-bahama-blue;
			}

			a {
				color: $color-pink3;
				font-weight: bold;
				font-size: 16px;
			}

			p {
				color: $color-siam;
				max-width: 670px;
			}
		}
	}

	.btn-finish {
		padding: 14px 32px;
		font-weight: bold;
		background-color: $color-blue;
		border-color: $color-blue;
	}

	.mx-input {
		color: $color-pink1;
	}

	.form-group {
		&__label {
			color: $color-blue1 !important;
		}
	}
}
</style>
